<div class="pagination-buttons">
  <div class="page-size">
    <select class="form-select dropdown" [(ngModel)]="selectedPageSize" (ngModelChange)="onPageSizeChange($event)">
      <option [value]="-1" disabled selected>Page size: {{ itemsPerPage }}</option>
      <option *ngFor="let option of [5, 10, 15]" [value]="option">{{ option }}</option>
    </select>
  </div>

  <div class="pagination-container ">
    <ul class="pagination-list no-select ">
      <li class="pagination-list-item dir pagination-prev d-flex arrow-style">
        <button class="btn p-0 m-0 pagination-btn"
                [disabled]="isDoublePreviousDisabled"
                (click)="previousPage(2)">
          <i class="fa-solid fa-angles-left" aria-hidden="true"></i>
        </button>
      </li>

      <li class="pagination-list-item dir pagination-prev d-flex arrow-style">
        <button class="btn p-0 m-0 pagination-btn"
                [disabled]="isPreviousDisabled"
                (click)="previousPage(1)">
                <i class="fa fa-angle-left" aria-hidden="true"></i>
        </button>
      </li>



      <li class="pagination-list-item"
          *ngFor="let page of pages"
          (click)="goToPage(page)"
          [ngClass]="{'active': page === currentPage}">
        {{ page }}
      </li>

      <li class="pagination-list-item dir pagination-next d-flex arrow-style">
        <button class="btn pagination-btn "
                [disabled]="isNextDisabled"
                (click)="nextPage(1)">
                <i class="fa fa-angle-right next-icon" aria-hidden="true"></i>
        </button>
      </li>

      <li class="pagination-list-item dir pagination-next d-flex arrow-style">
        <button class="btn pagination-btn "
                [disabled]="isDoubleNextDisabled"
                (click)="nextPage(2)">
          <i class="fa-solid fa-angles-right" aria-hidden="true"></i>
        </button>
      </li>
    </ul>
  </div>
</div>