import { ApplicationRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private isToggleSubject = new BehaviorSubject<boolean>(false);
  private isHiddenSubject = new BehaviorSubject<boolean>(false);
  isToggle$ = this.isToggleSubject.asObservable();
  isHidden$ = this.isHiddenSubject.asObservable();

  toggle(param: boolean = !this.isToggleSubject.value) {
    this.isToggleSubject.next(param);
  }

  hide(param: boolean = !this.isHiddenSubject.value) {
    this.isHiddenSubject.next(param);
  }
}