import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FeaturesComponent } from './features/features.component';
import {BrowserUtils} from "@azure/msal-browser";
import {MsalGuard} from "@azure/msal-angular";

const routes: Routes = [
  { path: '', redirectTo: 'select', pathMatch: 'full'  },

  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
  {
    path: '',
    component: FeaturesComponent,
    loadChildren: () => import('./features/features.module').then(m => m.FeaturesModule),
    canActivate:[MsalGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
