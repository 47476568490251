import {ChangeDetectorRef, Component, Input} from '@angular/core';
import { SidebarService } from '../shared/services/sidebar.service';

@Component({
  selector: 'app-feature',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
})
export class FeaturesComponent  {
  isHidden:boolean = false;
  constructor(private sidebarService: SidebarService, private cdr: ChangeDetectorRef ) { }

  ngOnInit() {
    this.sidebarService.isHidden$.subscribe((isHidden) => {
      this.isHidden = isHidden;
      this.cdr.detectChanges();
    })
  }
}
