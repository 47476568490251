import {
  DevEnvironmentLoginRedirectUri,
  DevEnvironmentPostLogoutUri, LocalEnvironmentPostLogoutUri, LocalEnvironmentRedirectLoginUri,
  ProdEnvironmentPostLogoutUri
} from "../app/shared/helpers/Uri/EnvironmentRedirectUri";

export const environment = {
  production: true,
  localUrl:"https://localhost:7015/api",
  localEngineUrl:"https://localhost:7051/api",
  apiUrl: 'https://sdsconnectors.be.dev1.solvextk.com/api',
  engineUrl: 'https://schooldatasync.engine.be.dev.solvextk.com/api',
  b2c_policies: {
    "names": {
      "signUpSignIn": "B2C_1_SDS_SISUP",
    },
    "authorities": {
      "signUpSignIn": {
      "authority": "https://b2csds.b2clogin.com/b2csds.onmicrosoft.com/B2C_1_SDS_SISUP",
      },

    },
    "authorityDomain": "b2csds.b2clogin.com"
  },
  azure_config: {
    "clientId": "1483908b-17f8-4262-b11a-fe0e27347626",
    "tenantId": "6495b430-b7cb-450b-b76b-6e8232a8d365",
    "authority": "https://b2csds.b2clogin.com/b2csds.onmicrosoft.com/B2C_1_SDS_SISUP",
    "scope": "https://b2csds.onmicrosoft.com/IEFTestApp/admin.read",
    "appId": "1483908b-17f8-4262-b11a-fe0e27347626",
    "redirecturi": `${LocalEnvironmentRedirectLoginUri}`,
    "postLogoutRedirectUri": `${LocalEnvironmentPostLogoutUri}`
  }
}
