import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import {AuthModule} from "../auth/auth.module";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    AuthModule
  ],
    exports: [
        SidebarComponent,
        NavbarComponent
    ],
    declarations: [
        SidebarComponent,
        NavbarComponent
    ],
    providers: [],
})
export class CoreModule { }
