import { ChangeDetectorRef, Component, ComponentRef, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnChanges{
  @Input() headers: TableHeaders[] = [];
  @Input() data: { [key:string]: any }[] = [];
  @Input() loading: boolean = false;
  @Input() config: TableConfig = {};
  @Output() actionChange: EventEmitter<ActionChange> = new EventEmitter<ActionChange>();
  @Output() componentClick: EventEmitter<any> = new EventEmitter<any>();

  dynamicInjector: Injector = {} as Injector;
  @Input()
  title!: string;
  selectAllChecked: boolean = false;

  items: items[] = [];
  checkboxes: boolean[] = [];


  constructor(private injector:Injector,
              private cdr: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['data']){
      this.items = this.data?.map(d => {
        return {
          data: d,
          isToggle: false
        }
      });
      this.checkboxes = new Array(this.items.length).fill(false);
    }

    if(changes['config']){
      if(!this.config?.textAlign)
        this.config.textAlign =  { header: 'left', body: 'left' };
      else
        this.config.textAlign = Object.assign({ header: 'left', body: 'left' }, this.config.textAlign);


      if(this.config.hasActions){
        if(!Array.isArray(this.config?.actions))
          this.config.actions = [];

        if(!this.config?.actionOrientation)
          this.config.actionOrientation = 'v';
      }
    }
  }

  getData(item: any, key: string, textTransform?: (value: any) => string) {
    const value = item[key];

    if (value instanceof Date) {
      return value.toLocaleDateString('es-ES');
    }

    if (textTransform) {
      return textTransform(value);
    }

    return value ?? 'N/A';
  }

  onSelectAllChange() {
    this.checkboxes.fill(this.selectAllChecked);
  }


  sortRow(header: TableHeaders) {
    if (['', 'down', undefined].includes(header?.sortState)) {
      header.sortState = 'up';
    } else if (header?.sortState === 'up') {
      header.sortState = 'down';
    }

    const sortOrder = header.sortState === 'up' ? 1 : -1;

    this.items.sort((a: any, b: any) => {
      const valueA = a.data[header.key];
      const valueB = b.data[header.key];

      if (valueA < valueB) {
        return -1 * sortOrder;
      }

      if (valueA > valueB) {
        return 1 * sortOrder;
      }

      return 0;
    });

    this.headers.forEach(h => {
      if (h.key !== header.key) {
        h.sortState = '';
      }
    });

    this.cdr.detectChanges();
  }

  dynamicSort(property: string, order: 'asc' | 'desc' = 'asc') {
    let sortOrder = order == 'asc' ? 1 : -1;
    return function (a: any, b: any) {
      let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }

  toggleRow(item: items){
    if(!item.isToggle){
      this.componentClick.emit(item.data);
      this.dynamicInjector = Injector.create({
        providers: [{ provide: 'data', useValue: item.data }],
        parent: this.injector
      })
    }

    item.isToggle = !item.isToggle;

    this.items.forEach(d => {
      if(d != item)
        d.isToggle = false;
    })
  }

  onActionChange(action: string, data:any){
    this.actionChange.emit({
      action,
      data
    });
  }
}

export interface TableHeaders{
  key: string;
  label: string;
  sortState?: 'up' | 'down' | '';
  bindData?: boolean;
  bindAtr?:string;
  textTransform?: (value: any) => string;
}

export interface TableConfig{
  hasActions?: boolean;
  haveCheckbox?: boolean;
  hasComponent?: boolean;

  textAlign?:{
    header?: 'left' | 'center' | 'right';
    body?: 'left' | 'center' | 'right';
  }

  actions?:{ label:string, key: string }[];
  actionOrientation?: 'h' | 'v';
  component?: any;
}

export interface TableAction{
  label:string; 
  key: string;
  type: TableActionType;
}

export enum TableActionType{
  Text,
  Switch
}

interface items{
  data: any;
  isToggle: boolean;
}

export interface ActionChange{
  action: string;
  data: any;
}
