import { Component, Input, OnChanges } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-custom-svg',
  template:`<div [innerHTML]="svg" [style]="style"></div>`,
  styles: ['svg, div { width: 100%; height: 100%; padding: 0; margin: 0; }']
})

export class CustomSvgComponent implements OnChanges {
  @Input() icon?: string;
  @Input() color: string = '#000';
  @Input() style:string = '';
  nativeElement: any;
  svg: SafeHtml = ``;

  constructor(private http:HttpClient, private sanitizer:DomSanitizer) { }

  ngOnChanges(changes: any) {
    if(changes['icon'])
      this.getIcon();

    if(changes['color'])
      this.setColor();
  }

  getIcon(){
    this.http.get(`assets/icons/${this.icon}.svg`, { responseType: 'text' })
      .subscribe(svg => {
        const _svg = svg.replace('<svg', `<svg id="${this.icon}"`)
        const sanitizedSvg = this.sanitizer.sanitize(0, _svg);
        
        if(sanitizedSvg)
          this.svg = this.sanitizer.bypassSecurityTrustHtml(sanitizedSvg);

        // Wait for the element to be rendered
        setTimeout(() => {
          this.setColor();
        }, 5);
      });
  }

  setColor(){
    if(!this.nativeElement){
      this.nativeElement = document.getElementById(this.icon ?? '');
    }

    this.nativeElement.style.fill = this.color;
  }
}

