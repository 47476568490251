import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AuthRoutingModule } from './auth-routing.module';
import { UnauthorizeComponent } from './pages/unauthorize/unauthorize.component';
import { LogoutComponent } from './pages/logout/logout.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        AuthRoutingModule
    ],
  exports: [
    UnauthorizeComponent,
    LogoutComponent
  ],
    declarations: [
        UnauthorizeComponent,
        LogoutComponent,

    ],
})
export class AuthModule { }
