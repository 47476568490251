import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Pipe({
    name: 'validations',
    pure: false
})

export class ValidationsPipe implements PipeTransform {
    transform(value: AbstractControl | null, ...args: any[]): string {
        if(!value || !value.touched || value.valid || !value.errors) return "";

        const error = Object.keys(value.errors)[0];

        const message = validations[error];
        const field = args[0] ?? "";
        const params = args.slice(1);

        params[0] = this.getParams(error, value.errors[error]) ?? params[0]

        return message.replace("$0", field)
                      .replace("$1", params[0] ?? '')
                      .replace("$2", params[1] ?? '');
    }

    getParams(errorName:string, error:ValidationErrors){
        switch(errorName){
            case "minlength":
            case "maxlength":
                return error['requiredLength'];

            case "min":
                return error['min'];

            case "max":
                return error['max'];

            default:
                return "";
        }
    }
}

const validations: { [key:string]: string } =
{
    "required": 'El campo $0 es requerido',
    "minlength": 'El campo $0 debe tener al menos $1 caracter(es)',
    "maxlength": 'El campo $0 debe tener un maximo de $1 caracteres',
    "email": 'El campo $0 debe ser un email valido',
    "pattern": 'El campo $0 no es valido $1',
    "min": 'El campo $0 debe ser mayor a $1',
    "max": 'El campo $0 debe ser menor a $1',
    "samePwd": "Las contraseñas no coinciden",
    "pdwPolicy": "Mínimo 8 caracteres con símbolos, números, letras mayúsculas y minúsculas."
}
