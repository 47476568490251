<div class="row card-container">
  <div class="col col-3">
    <div class="card shadow">
      <div class="card-body text-center">
        <img src="assets/icons/shield.svg" width="60" class="" alt="">
        <h5 class="card-title">Teams Connector</h5>
        <p class="card-text">15</p>
      </div>
    </div>
  </div>

  <div class="col col-3">
    <div class="card shadow">
      <div class="card-body text-center">
        <img src="assets/icons/shield.svg" width="60" class="" alt="">
        <h5 class="card-title">Azure AD Connector</h5>
        <p class="card-text">15</p>
      </div>
    </div>
  </div>

  <div class="col col-3">
    <div class="card shadow">
      <div class="card-body text-center">
        <img src="assets/icons/shield.svg" width="60" class="" alt="">
        <h5 class="card-title">Moodle Connector</h5>
        <p class="card-text">15</p>
      </div>
    </div>
  </div>
</div>