<div class="file-drop-area" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)" [ngClass]="{'border-danger': isNotAnImage}"
[style.maxHeight]="maxHeigth"
[style.maxWidth]="maxWidth">
  <div class="drop-content">
    <span class="drop-text" *ngIf="!imageUrl">Drag and drop an image file here</span>
    <img *ngIf="imageUrl" class="img-fluid" [src]="imageUrl" alt="Dropped Image" 
    [style.maxHeight]="maxHeigth"
    [style.maxWidth]="maxWidth">
  </div>
</div>
