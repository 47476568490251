<div class="custom-stepper">
  <ng-container *ngFor="let step of items; let i = index">
    <div class="step" [class.active]="currentStep === i || isStepCompleted(i)" (click)="onStepPress(i)">
      <div class="circle">
        <i class="fa fa-{{ step.icon }}" [ngClass]="isStepCompleted(i) ? 'fa-check' : ''" aria-hidden="true"></i>
      </div>
      <span class="title">{{ step.label }}</span>
    </div>
    <div class="line" *ngIf="i !== items.length - 1">
      <div class="progress" [style.width.%]="getProgressPercentage(i)"></div>
    </div>
  </ng-container>
</div>
