export class localStorageHelper{
    

}

export const SetValue = (key:string, value:any) =>{
    localStorage.setItem(key, JSON.stringify(value))
}

export const GetValue = (key:string) => {
    let json: string | null = localStorage.getItem(key);
    return json ? JSON.parse(json) : null;
}

export const RemoveValue = (key:string) => {
    localStorage.removeItem(key)
}

export default {
    SetValue,
    GetValue,
    RemoveValue
}