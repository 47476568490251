<div class="app-container">
  <div class="sidebar-container" *ngIf="!isHidden">
    <app-sidebar></app-sidebar>
  </div>
  <div class="page-container">
    <app-navbar></app-navbar>
    <main class="page-content" [ngClass]="{'container-100': isHidden}">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
