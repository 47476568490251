import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { OrganizationGuard } from '../shared/guards/organization.guard';
import {MsalGuard} from "@azure/msal-angular";

export const routes: Routes = [
  { path: 'organization', loadChildren: () => import('./organization/organizations.module').then(m => m.OrganizationsModule), canActivate: [MsalGuard], data: { tittle: 'Organizations' } },
  { path: 'connector', loadChildren: () => import('./connector/connectors.module').then(m => m.ConnectorsModule), canActivate: [MsalGuard], data: { tittle: 'Connectors' } },
  { path: 'migrations', loadChildren: () => import('./migrations/migrations.module').then(m => m.MigrationsModule), canActivate: [MsalGuard], data: { tittle: 'Migrations' } },
  { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule), canActivate: [MsalGuard], data: { tittle: 'Users' } },
  { path: 'actions', loadChildren: () => import('./actions/actions.module').then(m => m.ActionsModule), canActivate: [MsalGuard], data: { title: 'Actions' } },
  { path: 'events', loadChildren: () => import('./events/events.module').then(m => m.EventsModule), canActivate: [MsalGuard], data: {tittle: 'Eventos'} },
  { path: 'event-workflow/:id', loadChildren: () => import('./event-workflow/event-workflow.module').then(m => m.EventWorkflowModule), canActivate: [MsalGuard], data: {tittle: 'Eventos'} },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class FeaturesRoutingModule { }
