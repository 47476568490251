import {MsalGuardConfiguration, MsalInterceptorConfiguration, ProtectedResourceScopes} from "@azure/msal-angular";
import {
  BrowserCacheLocation,
  Configuration,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from "@azure/msal-browser";
import {environment} from "src/environments/environment";

export const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.azure_config.clientId,
    authority: environment.b2c_policies.authorities.signUpSignIn.authority,
    knownAuthorities: [environment.b2c_policies.authorityDomain],
    redirectUri: environment.azure_config.redirecturi,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE,
  },
  system: {
    allowNativeBroker: false,
    /*loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false
    }*/
  },

};

export const loginRequest = {

  scopes: ['openid'],
  state: "success",
  prompt: "login",
};
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}


