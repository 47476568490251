import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  @Input() items: StepperItem[] = [];
  @Input() currentStep: number = 0;
  @Output() currentStepChange: EventEmitter<number> = new EventEmitter<number>();

  @Input() blockChange: boolean = false;
  progress: number = 0;

  ngOnInit() {
    this.calculateProgress();
  }

  onStepPress(index: number) {
    if (!this.blockChange) {
      this.currentStepChange.emit(index);
      this.currentStep = index;
      this.calculateProgress();
    }
  }

  calculateProgress() {
    const totalSteps = this.items.length;
    this.progress = ((this.currentStep + 1) / totalSteps) * 100;
  }

  getProgressPercentage(index: number): number {
    const completedSteps = this.currentStep;

    if (this.isStepCompleted(index)) {
      return 100;
    } else if (index === completedSteps) {
      return 50
    } else {
      return 0;
    }
  }

  isStepCompleted(index: number): boolean {
    const completedSteps = this.currentStep;
    return index < completedSteps;
  }
}

export interface StepperItem {
  label: string;
  icon: string;
  active?: boolean;
}
