import {CommonModule, NgOptimizedImage} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CustomSvgComponent } from './components/custom-svg/custom-svg.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { TranslateModule } from '@ngx-translate/core';
import { DebounceDirective } from './directives/debounce.directive';
import { FileDropComponent } from './components/fileDrop/fileDrop.component';
import { ValidationsPipe } from './pipes/validations.pipe';
import { LoadingComponent } from './components/loading/loading.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { DatatableComponent } from './components/datatable/datatable.component';
import {FormsModule} from "@angular/forms";
import { FilterButtonsComponent } from './components/filters/filter-buttons/filter-buttons.component';
import {AuthModule} from "../auth/auth.module";


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    AuthModule,
  ],
  exports: [
    CustomSvgComponent,
    PaginationComponent,
    FileDropComponent,
    LoadingComponent,
    TranslateModule,
    DebounceDirective,
    ValidationsPipe,
    StepperComponent,
    DatatableComponent,
    FilterButtonsComponent,
  ],
    declarations: [
        CustomSvgComponent,
        PaginationComponent,
        FileDropComponent,
        LoadingComponent,
        DebounceDirective,
        ValidationsPipe,
        StepperComponent,
        DatatableComponent,
        FilterButtonsComponent,
    ],
    providers: [],
})
export class SharedModule { }
