import {
  DevEnvironmentLoginRedirectUri,
  LocalEnvironmentPostLogoutUri
} from "../app/shared/helpers/Uri/EnvironmentRedirectUri";

export const environment = {
  production: true,
  localUrl: "https://localhost:7015/api",
  localEngineUrl: "https://localhost:7051/api",
  apiUrl: 'https://sdsconnectors.be.dev1.solvextk.com/api',
  engineUrl: 'https://schooldatasync.engine.be.dev.solvextk.com/api',
  azure_config: {
    "clientId": "a43bfe3c-8ebb-43cb-968f-13d7ea2fc727",
    "authority": "https://login.microsoftonline.com/6b802ad0-e60c-469b-9c30-6e5bc717c892",
    "scope": "https://dannydeveloper.onmicrosoft.com/0d5192ff-bf5b-42a0-8212-a5be84c9e64b/Api.ReadWrite",
    "redirecturi": `${DevEnvironmentLoginRedirectUri}`,
    "postLogoutRedirectUri": `${LocalEnvironmentPostLogoutUri}`
  }
}
