import { Injectable } from '@angular/core';
import {MsalService} from "@azure/msal-angular";
import {AlertService} from "../alert.service";

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  constructor(private authService: MsalService, private alertService: AlertService) { }

  async getUserInfo(): Promise<{ userName: string | unknown, lastName: string | unknown }> {
    const activeAccount = await this.authService.instance.getActiveAccount();
    if (activeAccount ) {
      const userName = activeAccount.idTokenClaims?.['given_name'] || 'Desconocido';
      const lastName = activeAccount.idTokenClaims?.['family_name'] || 'Desconocido';
      return { userName, lastName };
    } else {
      throw new Error('No hay usuario activo.');
    }
  }
}

