import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";
import {
  BrowserCacheLocation,
  Configuration,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication
} from "@azure/msal-browser";
import { environment } from "../../../environments/environment";

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
const SCOPE_VALUE = ['user.read'];

export const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.azure_config.clientId,
    authority: environment.azure_config.authority,
    redirectUri: environment.azure_config.redirecturi,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE,
  },
  system: {
    allowNativeBroker: false,
  }
};

export const loginRequest = {
  scopes: ['openid'],
  state: "success",
  prompt: "login",
};

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(GRAPH_ENDPOINT, SCOPE_VALUE);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [environment.azure_config.scope],
    },
  };
}