import { Component, OnInit } from '@angular/core';
import { SidebarService } from 'src/app/shared/services/sidebar.service';
import {MsalService} from "@azure/msal-angular";
import {AlertService} from "src/app/shared/services/alert.service";
import {UserInfoService} from "src/app/shared/services/b2c-user-info/user-info.service";


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  isCollapsed: boolean = false;
  isHidden: boolean = false;
  userName: string | unknown;
  lastName: string | unknown;

  public popoverActive = false;
  Menus: { name: string; route: string; icon: string; }[] = [
    { name: 'organizaciones', route: '/organization/select', icon: 'homeIcon.svg' },
    { name: 'Conectores', route: '/connector', icon: 'conectorIcon.svg' },
    { name: 'Migraciones', route: '/migrations/details/1', icon: 'migrationIcon.svg' },
    { name: 'Users', route: '/users', icon: 'settingsIcon.svg' },
    { name: 'Configuración', route: '/organization/view', icon: 'settingsIcon.svg' }
  ];
  eventMenu =
  {
    name: 'Eventos',
    route: '/events'
  }


  public togglePopover(): void {
    this.popoverActive = !this.popoverActive;
  }
  onClosePopover() {
    this.popoverActive = false;
  }
  constructor(private sidebarService: SidebarService, private authService: MsalService, private alertService:AlertService, private userInfo: UserInfoService) { }


  async ngOnInit(): Promise<void> {
    try {
      const userInfo = await this.userInfo.getUserInfo();
      this.userName = userInfo.userName;
      this.lastName = userInfo.lastName;
    } catch (error) {
      this.alertService.error("No hay usuario activo");
    }

    this.isCollapsed = this.isMobile;
    this.sidebarService.isToggle$.subscribe((isToggle) => {
      this.isCollapsed = isToggle;
    })

    this.sidebarService.isHidden$.subscribe((isHidden) => {
      this.isHidden = isHidden;
    })
  }

  toggleSidebar() {
    this.isCollapsed = !this.isCollapsed;
  }

  get isMobile(): boolean {
    return window.innerWidth < 768;
  }
}
