import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GetValue } from 'src/app/shared/helpers/localstorage';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private toastr: AlertService,
              private router:Router) { }

  ngOnInit() {
    let organization = GetValue("organization");

    if(!organization){
      this.router.navigate(['/organization/select']);

    }
  }

  count = 0;
  showSuccess() {
    this.count++;
    this.toastr.info('Hello world!' + this.count );
  }
}
