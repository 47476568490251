import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeaturesComponent } from './features.component';
import { HomeComponent } from './home/home.component';
import { FeaturesRoutingModule } from './features-routing.module';
import { CoreModule } from '../core/core.module';
import {AuthModule} from "../auth/auth.module";
import {SharedModule} from "../shared/shared.module";


@NgModule({
  imports: [
    CommonModule,
    FeaturesRoutingModule,
    CoreModule,
    AuthModule,
    SharedModule
  ],
  declarations: [
    FeaturesComponent,
     HomeComponent,
    ],
  exports: [
    FeaturesComponent,
    HomeComponent
  ]
})

export class FeaturesModule { }
