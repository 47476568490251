import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UnauthorizeComponent } from './pages/unauthorize/unauthorize.component';
import {MsalGuard, MsalRedirectComponent} from "@azure/msal-angular";


export const routes: Routes = [
    { path: 'login', component: MsalRedirectComponent, canActivate: [
        MsalGuard
      ] },
    { path: 'unauthorize', component: UnauthorizeComponent },
    { path: '**', redirectTo: 'organization/select', pathMatch: 'full' }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class AuthRoutingModule { }
