import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LogoutPageCommunicationService {
  private readonly channel = new BroadcastChannel('logout-channel');
  sendLogoutSignal() {
    this.channel.postMessage('logout');
  }

  listenForLogoutSignal(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.channel.onmessage = (event) => {
        if (event.data === 'logout') {
          resolve();
        }
      };
    });
  }
}
