import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-fileDrop',
  templateUrl: './fileDrop.component.html',
  styleUrls: ['./fileDrop.component.scss']
})
export class FileDropComponent implements OnChanges{
  @Input() imageUrl: string | undefined;
  @Input() maxHeigth: string = "100%";
  @Input() maxWidth: string = "100%";
  styles?: { [key: string]: string };
  isNotAnImage:boolean = false;

  constructor(private alertService:AlertService){}

  ngOnChanges(): void {
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files) {
      const imageFile = files[0];
      if (this.isImageFile(imageFile)) {
        this.isNotAnImage = false;
        const reader = new FileReader();
        reader.onload = () => {
          this.imageUrl = reader.result as string;
        };
        reader.readAsDataURL(imageFile);
      }
      else{
        this.isNotAnImage = true;
        this.alertService.error("el archivo no es una imagen")
      }
    }
  }

  private isImageFile(file: File): boolean {
    return file.type.startsWith('image/');
  }
}
