<div id="sidebar" [ngClass]="{ 'sidebar-collapse': isCollapsed, 'sidebar-mobile': isMobile }">
  <main class="sidebar-content">
    <header class="branding">
      <a class="branding-link">
        <img src="assets/icons/sidebar/LogoImg.png" alt="">School Data SYNC
      </a>
    </header>

    <nav class="sidebar-menu">

      <a href="#" class="sidebar-menu__events mb-3" [routerLink]="eventMenu.route">
        Eventos
      </a>

      <h3 class="sidebar-menu-title">Main</h3>
      <ul class="list">
        <li *ngFor="let menu of Menus" class="list-item" [routerLink]="menu.route">
          <div class="item" routerLinkActive="active-item">
            <img src="./assets/icons/{{ menu.icon }}" alt="{{ menu.name }}" class="icon" />
            <label class="item-label" routerLinkActive="active-label-item" *ngIf="!isCollapsed">
              {{ menu.name | titlecase }}
            </label>
          </div>
        </li>
      </ul>
    </nav>

    <footer class="sidebar-footer">
      <img class="sidebar-footer-img" src="./assets/icons/sidebar/profileIcon.png" alt="" (click)="togglePopover()">
      <div class="sidebar-info">
        <h3 class="sidebar-footer-title">{{userName}}</h3>
        <p class="sidebar-footer-text">{{lastName}}</p>
      </div>
      <div class="popover__container">
        <div class="popover__content" [ngClass]="{ 'active': popoverActive }">
          <app-logout></app-logout>
        </div>
      </div>
    </footer>
  </main>

</div>
