import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-filter-buttons',
  templateUrl: './filter-buttons.component.html',
  styleUrls: ['./filter-buttons.component.scss']
})
export class FilterButtonsComponent {
  searchParam?:string;
  @Input() filterOptions: FilterOptions = {};
  @Output() searchEvent: EventEmitter<string> = new EventEmitter<string>();

  pagination = {
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1,
    totalRecords: 1
  }

  onSearch(search: string) {
    this.searchEvent.emit(search);
  }
}
export interface FilterOptions {
  showSearchBtn?: boolean;
  showClockBtn?: boolean;
  showFilterBtn?: boolean;
  showSortBtn?: boolean;
  showDeleteBtn?: boolean;
}
