import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { filter } from 'rxjs';
import {GetValue, RemoveValue, SetValue} from 'src/app/shared/helpers/localstorage';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  pageTitle: string = "Migrations";

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private msalService: MsalService) { }

  ngOnInit(): void {
    const storedTitle = GetValue('pageTitle');
    this.pageTitle = storedTitle || this.pageTitle;

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.updateTitle();
    });
  }

  updateTitle(): void {
    let currentRoute = this.activatedRoute;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }

    const titleFromRoute = currentRoute.snapshot.data['title'] || "Migrations";

    this.pageTitle = titleFromRoute;
    RemoveValue('pageTitle');
    SetValue('pageTitle', titleFromRoute);
    this.pageTitle = GetValue('pageTitle');
  }

}
