<div class="button-container">
  <div class="child-searchbar" *ngIf="filterOptions.showSearchBtn">
    <input type="text" class="search-input" placeholder="Buscar..." (debounce)="onSearch($event)">
    <img src="assets/icons/searchIcon.svg" alt="filter">
  </div>
  <div class="clock-btn" *ngIf="filterOptions.showClockBtn">
    <button class="btn btn-outline-primary add-btn">Seleccionar Fecha
      <img src="assets/icons/clockIcon.svg" alt="filter">
    </button>
  </div>
  <div class="btn-filter" *ngIf="filterOptions.showFilterBtn">
    <button class="btn btn-outline-primary add-btn">Filtros
      <img src="assets/icons/filterIcon.svg" alt="filter" height="20" width="20">
    </button>
  </div>
  <div class="btn-sort" *ngIf="filterOptions.showSortBtn">
    <button class="btn btn-outline-primary add-btn">Organizar
      <img src="assets/icons/sortIcon.svg" alt="filter">
    </button>
  </div>
  <div class="btn-delete" *ngIf="filterOptions.showDeleteBtn">
    <button class="btn btn-outline-primary add-btn">Borrar
      <img src="assets/icons/deleteIcon.svg" alt="filter">
    </button>
  </div>
</div>
