import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AlertService {
  options: any = {
    timeOut: 3000,
    progressBar: true,
    easing: 'ease-in',
    easeTime: 200,
    tapToDismiss: true,
    newestOnTop: true,
    positionClass: 'toast-bottom-right',
  };

  constructor(private toastr: ToastrService) {}

  success(message: string, title?: string) {
    return this.toastr.success(message, title, this.options);
  }

  error(message: string, title?: string) {
    return this.toastr.error(message, title, this.options);
  }

  warning(message: string, title?: string) {
    return this.toastr.warning(message, title, this.options);
  }

  info(message: string, title?: string) {
    return this.toastr.info(message, title, this.options);
  }

  clear() {
    return this.toastr.clear();
  }
}
