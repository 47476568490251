import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subject} from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import {Router} from "@angular/router";
import {LogoutPageCommunicationService} from "./shared/services/logout-page-communication.service";
import {DevEnvironmentPostLogoutUri, LocalEnvironmentPostLogoutUri} from "./shared/helpers/Uri/EnvironmentRedirectUri";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'SDS-Login';
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  private logoutRequest = {
    postLogoutRedirectUri: `${DevEnvironmentPostLogoutUri}`,
  };
 loginRequest = {
    scopes: ['openid'],
    state: "success",
    prompt: "login"
  };
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private logoutPageComunicationService: LogoutPageCommunicationService,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    await this.authService.instance.handleRedirectPromise();
    this.setupMsalBroadcastSubscriptions();
    await this.login();
    this.logoutPageComunicationService.listenForLogoutSignal().then(async () => {
      await this.authService.logoutRedirect(this.logoutRequest);
    });
  }

  setupMsalBroadcastSubscriptions() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
        takeUntil(this._destroying$)
      )
      .subscribe(async () => {
        if (this.authService.instance.getAllAccounts().length === 0) {

          await this.authService.instance.loginRedirect(this.loginRequest);
        } else {
          this.setLoginDisplay();
        }
      });

    this.checkInteractionStatus();

    this.acquireTokenAfterLogin();
  }

  acquireTokenAfterLogin(){
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) =>
          msg.eventType === EventType.LOGIN_SUCCESS ||
          msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {

        let payload = result.payload as AuthenticationResult;
        let idtoken = payload.idToken;
        localStorage.setItem('id_token', idtoken);
        return result;
      });
  }

  checkInteractionStatus(){
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();

      this.authService.instance.setActiveAccount(accounts[0]);
    }else if(!activeAccount){
      return;
    }
  }

  async login(userFlowRequest?: RedirectRequest) {
    if (await this.authService.instance.getActiveAccount()) {
      return;
    }

    const isUserRegistered = localStorage.getItem('isUserRegistered');

    if (!isUserRegistered) {

      localStorage.setItem('isUserRegistered', 'true');
    }

    if ( this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);

      await this.authService.instance.handleRedirectPromise().then(() => {
        if (isUserRegistered) {

          this.router.navigate(['/organization/select']);
        }
      });
    } else {
       await this.authService.loginRedirect(userFlowRequest);
    }
  }


  logout() {
    this.authService.logout();
    localStorage.removeItem("pageTitle");
  }


  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
