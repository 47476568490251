import { Directive, EventEmitter, Input, OnInit, OnDestroy, Output, ElementRef } from '@angular/core';
import { Observable, Subject, Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Directive({
  selector: '[debounce]'
})
export class DebounceDirective implements OnInit, OnDestroy {
  @Input() debounceTime = 1000;
  @Output() debounce = new EventEmitter<string>();
  private subscription?: Subscription;

  constructor(private element:ElementRef) { }

  ngOnInit() {
    this.subscription = fromEvent<KeyboardEvent>(
      this.element?.nativeElement,
      'keyup'
    )
    .pipe(debounceTime(this.debounceTime), distinctUntilChanged())
    .subscribe((event:any) => {
      this.debounce.emit(event?.target?.value);
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
