import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges, OnInit {
  @Input() selectedPageSize!: number
  @Input() totalItems!: number;
  @Input() itemsPerPage!: number;
  @Input() currentPage: number = 1;
  @Input() paginationType: PaginationType = PaginationType.Calculated;
  @Input() totalPages: number = 0;
  @Input() displayPageLimit: number = 5;
  @Output() paginationChange: EventEmitter<PaginationChangeEvent> = new EventEmitter<PaginationChangeEvent>();

  _totalPages: number = 0;
  ngOnInit() {
    this.selectedPageSize = -1;
  }

  ngOnChanges(changes: any) {
    if(changes["paginationType"] || changes["totalPages"] || changes["totalItems"] || changes["itemsPerPage"]){
      if(this.paginationType == PaginationType.Calculated){
        this._totalPages = Math.ceil(this.totalItems / this.itemsPerPage) ;
      }
      else{
        this._totalPages = this.totalPages > 0 ? this.totalPages : 1;
      }

      this._totalPages = this._totalPages == 0
      ? 1
      : this._totalPages;

      this.currentPage = this.currentPage > this._totalPages
      ? this._totalPages
      : this.currentPage;
    }
  }
  onPageSizeChange(newPageSize: number) {
      this.itemsPerPage = newPageSize;
      this.currentPage = 1;
      this.onPaginationChange();
  }

  get pages(): number[] {
    let pages =  Array(this._totalPages).fill(0).map((x, i) => i + 1);

    if(pages.length > this.displayPageLimit && this.currentPage >= this.displayPageLimit){
      let start = this.currentPage - Math.floor(this.displayPageLimit / 2);
      let end = this.currentPage + Math.floor(this.displayPageLimit / 2);

      if(start < 1){
        start = 1;
        end = this.displayPageLimit;
      }

      if(end > this._totalPages){
        start = this._totalPages - this.displayPageLimit + 1;
        end = this._totalPages;
      }

      pages = pages.slice(start - 1, end);
    }
    else{
      pages = pages.slice(0, this.displayPageLimit);
    }

    return pages
  }

  get isPreviousDisabled(): boolean {
    return this.currentPage == 1;
  }
  get isDoublePreviousDisabled(): boolean {
    return this.currentPage <= 1 || this.currentPage - 2 < 1;
  }
  get isNextDisabled(): boolean {
    return this.currentPage == this._totalPages;
  }
  get isDoubleNextDisabled(): boolean {
    return this.currentPage >= this._totalPages || this.currentPage + 2 > this._totalPages;
  }

  nextPage(nextNumber: number ){
    this.currentPage = this.currentPage + nextNumber;
    this.onPaginationChange();
  }

  previousPage(previousNumber:number){
    this.currentPage = this.currentPage - previousNumber;
    this.onPaginationChange();
  }

  goToPage(page: number){
    this.currentPage = page;
    this.onPaginationChange();
  }

  onPaginationChange(){
    this.paginationChange.emit({
      currentPage: this.currentPage,
      totalPages: this._totalPages,
      totalItems: this.totalItems,
      itemsPerPage: this.itemsPerPage
    })
  }
}

export enum PaginationType {
  Calculated = 1,
  Manual = 2
}

export interface PaginationChangeEvent{
  searchParam?: string;
  currentPage: number;
  totalPages: number;
  totalItems: number;
  itemsPerPage: number;
}
