import {Component, Inject} from '@angular/core';
import {MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService} from "@azure/msal-angular";
import {Router} from "@angular/router";
import {
  DevEnvironmentPostLogoutUri,
  LocalEnvironmentPostLogoutUri
} from "../../../shared/helpers/Uri/EnvironmentRedirectUri";
import {LogoutPageCommunicationService} from "../../../shared/services/logout-page-communication.service";
import { environment } from 'src/environments/environment';

  @Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
  })
  export class LogoutComponent {
    constructor(
      private authService: MsalService,
      private router: Router,
      private logoutPageCommunicationService: LogoutPageCommunicationService
    ) { }


  logout() {

    const logoutRequest = {
      postLogoutRedirectUri: environment.azure_config.postLogoutRedirectUri
    };

    this.authService.logoutRedirect(logoutRequest);
    this.logoutPageCommunicationService.sendLogoutSignal();
  }
  }
