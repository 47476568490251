import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DevEnvironmentPostLogoutUri } from "./shared/helpers/Uri/EnvironmentRedirectUri";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'SDS-Login';
  loginDisplay = false;

  private readonly _destroying$ = new Subject<void>();

  private logoutRequest = {
    postLogoutRedirectUri: `${DevEnvironmentPostLogoutUri}`,
  };

  loginRequest = {
    scopes: ['openid'],
    state: "success",
    prompt: "login"
  };

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    this.setupMsalBroadcastSubscriptions();
  }

  private setupMsalBroadcastSubscriptions() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
        }

        this.login();
      });

    this.checkInteractionStatus();
    this.acquireTokenAfterLogin();
  }

  private acquireTokenAfterLogin() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) =>
          msg.eventType === EventType.LOGIN_SUCCESS ||
          msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {

        let payload = result.payload as AuthenticationResult;
        let idtoken = payload.idToken;
        localStorage.setItem('id_token', idtoken);
        return result;
      });
  }

  private checkInteractionStatus() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
  }

  private setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  private checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    } else if (!activeAccount) {
      return;
    }
  }

  async login(userFlowRequest?: RedirectRequest) {
    if (await this.authService.instance.getActiveAccount()) {
      return;
    }

    const isUserRegistered = localStorage.getItem('isUserRegistered');

    if (!isUserRegistered) {
      localStorage.setItem('isUserRegistered', 'true');
    }

    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);

      await this.authService.instance.handleRedirectPromise().then(() => {
        if (isUserRegistered) {
          this.router.navigate(['/']);
        }
      });

    } else {
      await this.authService.loginRedirect(userFlowRequest);
    }
  }

  logout() {
    this.authService.logout();
    localStorage.removeItem("pageTitle");
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
