<table class="table table-responsive " aria-describedby="table">
  <thead [ngClass]="{ 'hasComponent': config.hasComponent}"
         class="text-{{ config.textAlign?.header}} table-config">
  <tr>

    <th class="text-center checkbox-th"  *ngIf="config?.haveCheckbox && items.length > 0">
      <input type="checkbox" id="selectAllCheckbox" class="checkbox-class" [(ngModel)]="selectAllChecked" (change)="onSelectAllChange()">
    </th>


    <th *ngFor="let header of headers" (click)="sortRow(header)"
        [ngClass]="{'active': header.sortState == 'up' || header.sortState == 'down'}">
      {{ header.label }}
      <i class="fa fa-arrow-{{ header.sortState }}" aria-hidden="true"></i>
    </th>

    <th  *ngIf="config?.hasActions">{{title}}</th>
  </tr>
  </thead>

  <tbody *ngIf="!loading">
    <ng-container *ngFor="let row of items; index as i">
      <tr class="row-separator text-{{ config.textAlign?.body}}">

        <ng-container *ngIf="config?.haveCheckbox && items.length > 0">
          <td class="text-center checkbox">
            <input type="checkbox" class="checkbox-class" [(ngModel)]="checkboxes[i]" (change)="onSelectAllChange()">
          </td>
        </ng-container>

        <ng-container *ngIf="config?.hasComponent && items.length > 0">
          <td class="text-center arrow-container" (click)="toggleRow(row)">
            <i class="fa fa-angle-{{ row.isToggle ? 'down' : 'right' }}"></i>
          </td>
        </ng-container>

        <ng-container *ngFor="let column of headers">
          <ng-container *ngIf="!column.bindData; else bindDataColumn">
            <td>
              {{ getData(row.data, column.key, column.textTransform) }}
            </td>
          </ng-container>

          <ng-template #bindDataColumn>
            <td [attr.data-internal-use]="getData(row.data, !column.bindAtr ? column.key : column.bindAtr)">
        <span *ngIf="column.key === 'stateName'">
          {{ getData(row.data, column.key) }}
          <img *ngIf="row.data.state === 1" src="assets/icons/checkIcon.svg" alt="Completada">
          <img *ngIf="row.data.state === 2" src="assets/icons/pendingIcon.svg" alt="Pendiente">
          <img *ngIf="row.data.state === 3" src="assets/icons/failedIcon.svg" alt="Failed">


        </span>
            </td>
          </ng-template>
        </ng-container>


        <td *ngIf="config?.hasActions">
          <div class="dropdown">
              <span class="pointer" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="text-active fa fa-ellipsis-{{ config.actionOrientation ?? 'v' }} ellipsis-content" aria-hidden="true"></i>
              </span>
              <ul class="dropdown-menu ">
                <li *ngFor="let action of config.actions" (click)="onActionChange(action.key, row.data)">
                  <a class="dropdown-item">
                    {{
                      action.label
                    }}
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>

        <tr *ngIf="row.isToggle">
          <td [attr.colspan]="headers.length + 1">
            <ng-container *ngComponentOutlet="config?.component; injector: dynamicInjector"></ng-container>
          </td>
        </tr>
    </ng-container>

    <tr *ngIf="items.length == 0 && !loading">
      <td [attr.colspan]="headers.length + 1">
        <div class="text-center">
          <h4>No hay data para mostrar</h4>
        </div>
      </td>
    </tr>
  </tbody>

  <tbody *ngIf="loading">
    <tr>
      <td [attr.colspan]="headers.length">
        <div class="loading-container text-center" *ngIf="loading">
          <i class="fa fa-2x fa-spinner fa-spin" aria-hidden="true"></i>
        </div>
      </td>
    </tr>
  </tbody>
</table>
